import React, {useEffect, useRef} from 'react';
import {confirmable, ConfirmDialog, createConfirmation} from 'react-confirm';
import styled from 'styled-components';

// refer to https://www.npmjs.com/package/react-confirm for documentation

interface Props {
  confirmation: React.ReactElement;
}

const Confirmation: ConfirmDialog<Props, boolean> = ({confirmation, proceed, show}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (show) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [show, dialogRef]);
  return (
    <Dialog ref={dialogRef}>
      {confirmation}
      <Buttons>
        <Button onClick={() => proceed(true)}>Yes</Button>
        <Button onClick={() => proceed(false)}>No</Button>
      </Buttons>
    </Dialog>
  );
}

const Alert: ConfirmDialog<Props, boolean> = ({confirmation, proceed, show}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (show) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [show, dialogRef]);
  return (
    <Dialog ref={dialogRef}>
      {confirmation}
      <Buttons>
        <Button onClick={() => proceed(false)}>Ok</Button>
      </Buttons>
    </Dialog>
  );
}


const Dialog = styled.dialog`
  border: #861912 solid 5px;
  border-radius: 20px;
  position: fixed;
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 20px;
  margin: auto;
  background-color: #F7E3E8;
  padding: 20px;
  top: 0;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 30px;
`

const Button = styled.button`
  font-size: 1em;
  border: none;
  color: white;
  background-color: black;
  padding: 10px 20px;
  border-radius: 30px;
`

export const confirm = createConfirmation(confirmable(Confirmation));

export const alert = createConfirmation(confirmable(Alert));
