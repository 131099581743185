import {AppStoreUrl, HouseholdBillingPage, SubscribePage} from './path';
import React from 'react';
import styled from 'styled-components';
import {appUpdateNeeded} from './billing';
import {useCache} from '../data/use_cache';
import {CacheKey} from '../data/client_cache';
import {HouseholdStatus} from '../common/model';

export const SignUpButton = ({subscribeButton}: {subscribeButton?: boolean}) => {
  const [householdStatus] = useCache(CacheKey.HouseholdStatus);
  let message = 'Sign Up';
  let href = HouseholdBillingPage;
  if (appUpdateNeeded()) {
    message = 'Update';
    href = AppStoreUrl;
  } else if (subscribeButton || householdStatus === HouseholdStatus.Unsubscribed) {
    message = 'Subscribe';
    href = SubscribePage;
  }
  return <SignUpButtonStyle href={href}>{message}</SignUpButtonStyle>
}

export const OptInButton = ({onClick}: {onClick: () => void}) => {
  return <SignUpButtonStyle onClick={onClick}>Opt In</SignUpButtonStyle>
}

const SignUpButtonStyle = styled.a`
  font-family: Jost-SemiBold, Arial, sans-serif;
  font-size: 1em;
  color: white;
  background-color: #5200BB;
  padding: 3px 12px;
  border-radius: 30px;
  margin-right: 8px;
  cursor: pointer;
  width: fit-content;
`;
