import React, {useEffect} from 'react';
import './header.css';
import {ReactComponent as BackArrow} from '../assets/arrow-previous-left-icon.svg';
import {ReactComponent as ForwardArrow} from '../assets/arrow-forward.svg';
import {ReactComponent as KebabIcon} from '../assets/ellipsis-vertical.svg';
import {Hymn} from '../sequencer';
import {CacheKey} from '../data/client_cache';
import {useCache} from '../data/use_cache';
import styled from 'styled-components';
import {
  appUpdateNeeded,
  synchronizeHouseholdStatus,
} from '../util/billing';
import {HouseholdStatus} from '../common/model';
import {SignUpButton} from '../util/sign_up_button';

interface Props {
  title: string;
  householdCreation?: boolean;
  hymn?: Hymn;
  onBack?: () => void;  //optional callback function for back button in header
  onKebabClick?: () => void;
  onSkipChooseChurch?: () => void;
  suppressSignUp?: boolean;
}

export const Header = ({title, householdCreation, onBack, onKebabClick, onSkipChooseChurch, suppressSignUp}: Props) => {

  useEffect(() => {
    void synchronizeHouseholdStatus({onlyIfNotSubscribed: true});
  }, []);

  const [householdStatus] = useCache(CacheKey.HouseholdStatus);

  const otherButtonsHidden = !onKebabClick && !onSkipChooseChurch;
  const isSignedUp =
      householdStatus === HouseholdStatus.Subscribed ||
      householdStatus === HouseholdStatus.Unsubscribed;
  const showSignup = otherButtonsHidden && (appUpdateNeeded() || !isSignedUp) && !householdCreation;
  return (
    <HeaderWrapper key='header'>
      {
        onBack
          ? <BackButtonWrapper><BackArrow className='backButton' onClick={onBack}/></BackButtonWrapper>
          : <LeftPlaceholder/>
      }
      <div className={onBack ? 'songTitle' : 'title'}>{title}</div>
      <HeaderIcons>
        {onKebabClick && <KebabIcon id="kebabIcon" onClick={() => onKebabClick()}/>}
        {onSkipChooseChurch &&
          <div id='chooseChurchSkip' onClick={onSkipChooseChurch}>
            <div style={{display: 'flex', }}>
              <div style={{marginTop: 7}}>Continue</div>
              <ForwardArrow style={{width: 36, height: 36}}/>
            </div>
          </div>
        }
        {showSignup && !suppressSignUp && <SignUpButton/>}
      </HeaderIcons>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 55px;
  height: 55px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a4a4a4;
  white-space: normal;
  background-color: white;
`

const HeaderIcons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  flex-grow: 1;
  flex-basis: 0;
`;

const LeftPlaceholder = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
`;
