import {useCallback, useEffect} from 'react';
import {CacheKey, cacheSet} from './client_cache';
import {useCache} from './use_cache';
import {Household, HouseholdStatus} from '../common/model';

function getHouseholdFromCache(
  token: string | undefined,
  lastName: string | undefined,
  email: string | undefined,
  churchId: number | undefined,
  status: HouseholdStatus | undefined
) {

  const householdFromCache: Household = {
    token: token,
    familyLastName: lastName,
    householdEmail: email,
    churchId: churchId,
    status: status
  }

  return householdFromCache;
}

export function useHousehold(): {
  household: Household | undefined,
  setHousehold: (household: Household | undefined) => void
} {

  const [token, setToken] = useCache(CacheKey.BillingHouseholdToken);
  const [lastName, setLastname] = useCache(CacheKey.FamilyLastName);
  const [email, setEmail] = useCache(CacheKey.HouseholdEmail);
  const [churchId, setChurchId] = useCache(CacheKey.HouseholdChurchId);
  const [status, setStatus] = useCache(CacheKey.HouseholdStatus);

  const [household, setHousehold] = useCache(CacheKey.Household);

  useEffect(() => {

    setHousehold(getHouseholdFromCache(token, lastName, email, churchId, status));
  }, [token, lastName, email, churchId, status, setHousehold]);

  const setHouseholdAndPushToServer = useCallback((newHousehold: Household | undefined) => {

    setToken(newHousehold?.token);
    setLastname(newHousehold?.familyLastName);
    setEmail(newHousehold?.householdEmail);
    setChurchId(newHousehold?.churchId);
    setStatus(newHousehold?.status);

    cacheSet(CacheKey.Household, newHousehold);

    cacheSet(CacheKey.PushHousehold, true);

    void synchronizeHouseholdWithServer();

  }, [
    setLastname,
    setEmail,
    setToken,
    setChurchId,
    setStatus,
  ]);

  return {household, setHousehold: setHouseholdAndPushToServer};
}

export async function synchronizeHouseholdWithServer() {
  //server stuff here
}