import {ReactComponent as BackArrow} from '../assets/arrow-previous-left-icon.svg';
import React, {useState} from 'react';
import {RequestSongPage} from './request_song_page';
import {ensureExists} from '../common/util';
import {Document, Page} from 'react-pdf';
import {Hymn} from '../sequencer';
import useWindowDimensions from '../util/useWindowDimensions';
import {encodeMusicURI, generateFileName} from '../util/path';
import {hymnalsPDFPath} from '../shared';
import {useUserAttributes} from '../data/use_user_attributes';
import styled from "styled-components";

interface Props {
  hymn: Hymn;
  onClose: () => void;
}

export function PdfViewerPage({hymn, onClose}: Props) {
  const [numPages, setNumPages] = useState<number | undefined>(undefined);
  const windowDimensions = useWindowDimensions();
  const pdfFile = generateFileName(hymn) + '.pdf';
  const pdfPath = `${hymnalsPDFPath}/${encodeMusicURI(ensureExists(hymn.hymnal))}/${encodeMusicURI(pdfFile)}`;
  const {isInternalUser} = useUserAttributes();

  return (
    <PdfDoc key='pdf'>
      <BackButton onClick={onClose}><BackArrow/></BackButton>
      <div>
        <div style={{...(!hymn.issue && {display: 'none'})}}>
          {
            hymn.issue && !isInternalUser
              ? <RequestSongPage issue={hymn.issue} hymnalName={ensureExists(hymn.hymnal)} songNumber={hymn.number}/>
              : null
          }
        </div>
        <div style={{...(hymn.issue && !isInternalUser && {display: 'none'})}}>
          <Document
            file={pdfPath}
            options={{workerSrc: "/pdf.worker.js"}}
            onLoadSuccess={({numPages}) => setNumPages(numPages)}
            onLoadError={console.error}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} key={page} width={windowDimensions.width}/>)}
          </Document>
        </div>
      </div>
    </PdfDoc>
  );
}

const PdfDoc = styled.div`
  overflow-y: scroll;
  overflow-x: clip;
  padding-bottom: 30px;
  transform: translateY(-60px);
`

const BackButton = styled.div`
  position: sticky;
  top: 20px;
  width: 12px;
  z-index:1000;
  background-color: rgba(255, 255, 255, 0.8);
  margin-left: 8px;
  padding: 3px 10px;
  border-radius: 3px;
`
