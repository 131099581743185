import "../settings_page.css"
import "./verse_count.css"
import {ReactComponent as CheckMarkIcon} from "../../assets/check-mark-icon.svg";
import {useCache} from "../../data/use_cache";
import {CacheKey} from "../../data/client_cache";
import {useCallback} from 'react';

type Props = {
  back: () => void;
}

export const VerseCount = (props: Props) => {

  const [verseCount, setVerseCount] = useCache(CacheKey.VerseCount);

  const onSetVerseCount = useCallback((num: number | undefined) => {
    setVerseCount(num);
    props.back();
  }, [setVerseCount, props]);



  return (
      <div className="settingsContent">
        <div className="settingsBox">
          <table className="settings verseCountSettings">
            <tbody>
            <tr className="setting" onClick={() => onSetVerseCount(1)}>
                <td className="verses">
                  1
                </td>
                <td className="checkMarkCell" >
                  <div className="checkMarkIcon" hidden={verseCount !== 1}>
                    <CheckMarkIcon />
                  </div>
                </td>
            </tr>
            <tr className="setting" onClick={() => onSetVerseCount(2)}>
              <td className="verses">
                2
              </td>
              <td className="checkMarkCell" >
                <div className="checkMarkIcon" hidden={verseCount !== 2}>
                  <CheckMarkIcon />
                </div>
              </td>
            </tr>
            <tr className="setting" onClick={() => onSetVerseCount(undefined)}>
              <td className="verses">
                All
              </td>
              <td className="checkMarkCell" >
                <div className="checkMarkIcon" hidden={verseCount === 1 || verseCount === 2}>
                  <CheckMarkIcon />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className="explanation">
          Verse Count determines how many times a song plays through,
          before stopping, repeating, or going to the next song.
          Setting the verse count to 'All' will make the song play through as
          many verses as are in a given song. If we do not have this information for
          a song, it will default to 4 verses.
        </div>
      </div>
  )
}