import React from 'react';
import {LibraryPage} from "./library_page";
import {ThisWeekPage} from "./this_week_page";
import {HelpPage} from "./help_page";
import {SettingsPage} from "./settings_page";
import {getCurrentPage, Pages} from "../util/path";
import {useLocation} from "../util/use_location";
import {handleCrash} from "../util/crash";
import {FavoritesPage} from "./favorites_page";
import { createGlobalStyle } from 'styled-components/macro';
import {useSetupIOSLogin} from "../authentication/apple_login";
import {ensureUnreachable} from "../common/util";
import {AdminInvite} from './admin_invite';
import {ChurchHouseholdInvite} from './church_household_invite';
import useWindowDimensions from '../util/useWindowDimensions';
import {HouseholdCreationPage} from './household_creation_page';

export const RootPage = () => {
  const {location} = useLocation();
  const windowDimensions = useWindowDimensions();
  useSetupIOSLogin();

  try {
    const page = getCurrentPage(location);
    const content = getContentForPage(page);
    return (
      <>
        <GlobalStyles $windowHeight={windowDimensions.height}/>
        {content}
      </>
    );
  } catch (error: any) {
    return handleCrash(error);
  }
}

function getContentForPage(page: Exclude<Pages, Pages.Demo>) {
  switch (page) {
    case Pages.Favorites:
      return <FavoritesPage/>;
    case Pages.Help:
      return <HelpPage/>;
    case Pages.Library:
      return <LibraryPage/>;
    case Pages.Settings:
      return <SettingsPage/>;
    case Pages.ThisWeek:
      return <ThisWeekPage/>;
    case Pages.AdminInvite:
      return <AdminInvite/>;
    case Pages.ChurchHouseholdInvite:
      return <ChurchHouseholdInvite/>;
    case Pages.HouseholdCreation:
      return <HouseholdCreationPage/>
    default:
      ensureUnreachable(page, 'Failed to cover all Page values');
  }
}

const GlobalStyles = createGlobalStyle<{$windowHeight: number}>`
  * {
    --view-height: ${props => props.$windowHeight}px;
  }
`;
