import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import '../shared.css';
import {Header} from './header';
import {Footer} from './footer';
import './settings_page.css'
import {VerseCount} from './settings/verse_count';
import {ReactComponent as ForwardArrow} from '../assets/arrow-next-right-icon.svg';
import {Login} from './settings/login';
import {ModalNotification} from '../modal';
import {cacheGet, CacheKey, cacheSet, enableShowAllHymnals, Guest} from '../data/client_cache';
import {useCache} from '../data/use_cache';
import {HouseholdSettingsPage} from './settings/household_settings_page';
import {HouseholdBillingPage} from '../util/path';
import {LocalStoragePage} from './settings/local_storage_page';
import {
    isInsideAppleAppStoreApp, isInsideGooglePlayApp, isInsideMobileApp,
    manageAppleSubscription,
    synchronizeHouseholdStatus
} from '../util/billing';
import {SignUpButton} from '../util/sign_up_button';
import styled from 'styled-components';
import {InnerPageContent, OuterPageContent, SettingsTransitionWrapper} from '../shared';
import {LoggingPage} from './settings/logging_page';
import {ChooseChurchPage} from './choose_church_page';
import {useChurch} from '../data/use_church';

enum Display {
    Settings = 'Settings',
    Login = 'Login',
    VerseCount = 'VerseCount',
    Household = 'Household',
    ChooseChurch = 'ChooseChurch',
    LocalStorage = 'LocalStorage',
    Logging = 'Logging',
}

export const SettingsPage = () => {
    const [modal, setModal] = useState('')
    const [display, setDisplay] = useState(Display.Settings);
    const [familyLastName] = useCache(CacheKey.FamilyLastName);
    const [householdEmail] = useCache(CacheKey.HouseholdEmail);
    const [isInternalUser] = useCache(CacheKey.IsInternalUser);
    const [user] = useCache(CacheKey.User);
    const [verseCount] = useCache(CacheKey.VerseCount);
    const {church} = useChurch();
    const churchName = church?.name ?? "No Church";

    const onShowHousehold = useCallback(() => {
        if (householdEmail) {
            setDisplay(Display.Household);
        } else {
            window.location.href = HouseholdBillingPage;
        }
    }, [householdEmail]);

    const onShowChooseChurch = useCallback(() => {
        setDisplay(Display.ChooseChurch)
    }, []);

    useEffect(() => {
        void synchronizeHouseholdStatus({onlyIfNotSubscribed: false});
    }, []);

    function renderPersonal() {
        return <div className="settingsBox">
            <div className="settingBoxHeader">Personal</div>
            <table className="settings">
                <tbody>
                    <tr onClick={onShowHousehold} className="setting arrow">
                        <td className="settingName">Household</td>
                        <td className="householdValue">
                        {
                            familyLastName
                              ? <div className="currentValue">{familyLastName}</div>
                              : <SignUpButton/>
                        }
                        </td>
                        <td className='settingAction'>
                            <ForwardArrow/>
                        </td>
                    </tr>
                    <tr onClick={onShowChooseChurch} className="setting arrow">
                        <td className="settingName">Church</td>
                        <td className="currentValueEllipse">{churchName}</td>
                        <td className='settingAction'><ForwardArrow/></td>
                    </tr>
                    <tr onClick={() => setDisplay(Display.Login)} className="setting arrow">
                        <td className="settingName">Individual</td>
                        <td className="currentValue">{user && user !== Guest ? user.name : "Sign In For Favorites"} </td>
                        <td className='settingAction'>
                            <ForwardArrow/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }


    function handleSongIntroToggle(e: any) {
        let isChecked = e.target.checked;
        cacheSet(CacheKey.SongIntro, isChecked)
    }

    function handlePdfAutoDisplayToggle(e: any) {
        let isChecked = e.target.checked;
        cacheSet(CacheKey.PdfAutoDisplay, isChecked)
    }

    function handleBackgroundPlaybackToggle(e: any) {
        let isChecked = e.target.checked;
        cacheSet(CacheKey.BackgroundPlayback, isChecked)
    }

    function handleShowAllHymnalsToggle(e: any) {
        let isChecked = e.target.checked;
        cacheSet(CacheKey.ShowAllHymnals, isChecked)
    }

    function renderSongSettings() {
        return <div className="settingsBox songSettings">
            <div className="settingBoxHeader">Song Options</div>
            <table className="settings">
                <tbody>
                <tr className="setting arrow" onClick={() => setDisplay(Display.VerseCount)}>
                    <td className="settingName">Verse Count</td>
                    <td className="currentValue">{verseCount ? verseCount : 'All'}</td>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                <tr className="setting">
                    <td className="settingName">Song Introduction</td>
                    <td className="settingAction" colSpan={2}>
                        <div className="toggle">
                            <input type="checkbox" onChange={e => handleSongIntroToggle(e)}
                                   defaultChecked={cacheGet(CacheKey.SongIntro)}/>
                        </div>
                    </td>
                </tr>
                <tr className="setting">
                    <td className="settingName">Show PDF on Play</td>
                    <td className="settingAction" colSpan={2}>
                        <div className="toggle">
                            <input type="checkbox" onChange={e => handlePdfAutoDisplayToggle(e)}
                                   defaultChecked={cacheGet(CacheKey.PdfAutoDisplay)}/>
                        </div>
                    </td>
                </tr>
                <tr className="setting">
                    <td className="settingName">Background Playback</td>
                    <td className="settingAction" colSpan={2}>
                        <div className="toggle">
                            <input type="checkbox" onChange={e => handleBackgroundPlaybackToggle(e)}
                                   defaultChecked={cacheGet(CacheKey.BackgroundPlayback)}/>
                        </div>
                    </td>
                </tr>
                { enableShowAllHymnals ?
                    <tr className="setting">
                        <td className="settingName">Show All Hymnals</td>
                        <td className="settingAction" colSpan={2}>
                            <div className="toggle">
                                <input type="checkbox" onChange={e => handleShowAllHymnalsToggle(e)}
                                       defaultChecked={cacheGet(CacheKey.ShowAllHymnals)}/>
                            </div>
                        </td>
                    </tr> : undefined
                }
                </tbody>
            </table>
        </div>;
    }

    function renderInternalSettings() {
        return <div className="settingsBox songSettings">
            <div className="settingBoxHeader">Internal</div>
            <table className="settings">
                <tbody>
                <tr className="setting arrow" onClick={() => setDisplay(Display.LocalStorage)}>
                    <td className="settingName">Local Storage</td>
                    <td className="currentValue"/>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                <tr className="setting arrow" onClick={() => setDisplay(Display.Logging)}>
                    <td className="settingName">Logging</td>
                    <td className="currentValue"/>
                    <td className='settingAction'>
                        <ForwardArrow/>
                    </td>
                </tr>
                {
                  isInsideMobileApp() &&
                  <tr className="setting arrow" onClick={() => {
                      void synchronizeHouseholdStatus()
                  }}>
                      <td className="settingName">Synchronize Household Status</td>
                      <td className="currentValue"/>
                      <td className='settingAction'>
                          <ForwardArrow/>
                      </td>
                  </tr>
                }
                </tbody>
            </table>
            <InternalManageSubscription/>
        </div>;
    }

    function renderAppSettings() {
        return <div className="settingsBox appSettings hidden">
            <div className="settingBoxHeader">Display</div>
            <table className="settings">
                <tbody>
                <tr className="setting arrow">
                    <td className="settingName">Initial Screen</td>
                    <td className="currentValue">Library</td>
                    <td className='settingAction'>
                            <ForwardArrow />
                        </td>
                    </tr>
                    <tr className="setting arrow">
                        <td className="settingName" colSpan={2}>Text Size</td>
                        <td className='settingAction'>
                            <ForwardArrow />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>;
    }

    const showInternalSettings = isInternalUser ||
      window.location.hostname.startsWith('test.singyourpart.app') ||
      (window.location.protocol !== undefined && window.location.protocol !== 'https' && !isInsideGooglePlayApp());

    // TODO(hewitt): window.location.protocol must not be 'https' for Google Play app - find out what it is
    if (isInsideGooglePlayApp()) {
      console.log(`Google Play window.location.protocol: ${window.location.protocol}`);
    }

    function renderSettings() {
        return (
          <OuterPageContent>
              <Header title='Settings'/>
              <InnerPageContent>
                  <div className="settingsContent">
                      {renderPersonal()}
                      {renderSongSettings()}
                      {renderAppSettings()}
                      {showInternalSettings && renderInternalSettings()}
                  </div>
              </InnerPageContent>
              <Footer />
          </OuterPageContent>
        )}

    const wrapWithTransition = useCallback((title: string, component: ReactElement, page: Display) => (
      <SettingsTransitionWrapper $left={(display === page) ? "0" : "100%"}>
        <Header title={title} onBack={() => setDisplay(Display.Settings)}/>
        {component}
      </SettingsTransitionWrapper>
    ), [display]);

    function renderVerseCount() {
        return wrapWithTransition(
          'Verse Count',
          <VerseCount back={() => {setDisplay(Display.Settings)}} />,
          Display.VerseCount
        );
    }

    function renderLocalStorage() {
        return wrapWithTransition(
          'Local Storage',
          <LocalStoragePage/>,
          Display.LocalStorage
        );
    }

    function renderLogging() {
        return wrapWithTransition(
          'Logging',
          <LoggingPage/>,
          Display.Logging
        );
    }

    function renderLogin() {
        return wrapWithTransition(
          'Login',
          <Login visible={display === Display.Login} />,
          Display.Login
        );
    }

    function renderHousehold() {
        return wrapWithTransition(
            'Household',
            <HouseholdSettingsPage visible={display === Display.Household} />,
            Display.Household
        );
    }

    const onSettingChanged = useCallback(() => {
        setDisplay(Display.Settings);
    }, []);

    const renderChooseChurch = useCallback(() => {
        return wrapWithTransition(
          'Choose Church',
          <ChooseChurchPage onSettingChanged={onSettingChanged} location={'settings'}/>,
          Display.ChooseChurch
        );
    }, [onSettingChanged, wrapWithTransition]);

    return (
      <div>
          {display === Display.Settings &&
            <ModalNotification
                popup={modal}
                close={() => setModal('')}
             />
          }
          {renderSettings()}
          {renderHousehold()}
          {renderChooseChurch()}
          {renderLogin()}
          {renderVerseCount()}
          {renderLocalStorage()}
          {renderLogging()}
      </div>
    )
}

const InternalManageSubscription = () => {
    // TODO(hewitt): how to bring up Google Play subscription management UI?
    if (!isInsideAppleAppStoreApp()) {
        return null;
    }
    return (
      <InternalManageSubscriptionButton onClick={manageAppleSubscription}>
          Manage Subscription
      </InternalManageSubscriptionButton>
    );
}

const InternalManageSubscriptionButton = styled.button`
  color: white;
  background-color: black;
  font-size: 1em;
  padding: 5px 15px;
  border-radius: 30px;
  margin-top: 10px;
  cursor: pointer;
`
