import React, {useCallback, useEffect, useState} from 'react';
import '../shared.css';
import './choose_church_page.css';
import {ReactComponent as CloseCircleIcon} from "../assets/close-circle.svg";
import {ReactComponent as SearchIcon} from '../assets/search.svg';
import {useChurch} from '../data/use_church';
import * as server_api from '../common/server_api';
import {Church} from '../common/model';
import {InnerPageContent, OuterPageContent} from '../shared';
import {useCache} from '../data/use_cache';
import {CacheKey} from '../data/client_cache';

const testUserEmail = 'testmailcrescendo@gmail.com';
const testChurchName = 'Test Church';

export interface Props {
  onSettingChanged?(): void;
  back?: () => void;
  location: string
}

export const ChooseChurchPage = (props: Props) => {
  const [churches, setChurches] = useState<Church[]>([]);
  const {church, setChurch} = useChurch();
  const [searchString, setSearchString] = useState('');
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [user] = useCache(CacheKey.User);

  useEffect(() => {
    (async () => {
      setChurches(
          (await server_api.getChurches()).filter(church =>
              church.name !== testChurchName ||
              (user !== 'guest' && user?.email === testUserEmail)
          )
      )
    })();
  }, [user]);

  const onSetChurch = useCallback((church: Church | undefined) => {

      setChurch(church);
      props.onSettingChanged?.();
    }, [props, setChurch]
  );

  const cancelChurchSearch = () => {
      setSearchString('');
  };

  function renderChurchSearchControl() {
      return (
        <div id="subHeader" className="opaque-background">
          <table id="chooseChurchSearchTable">
            <tbody>
            <tr>
              <td className="searchIconCell">
                <div className="searchIcon">
                  <SearchIcon />
                </div>
              </td>
              <td key='searchDiv' className="searchCell">
                <input
                  key='search'
                  id='song-search-bar'
                  type='text'
                  placeholder='Search'
                  onChange={(event) => setSearchString((event.target as HTMLInputElement).value)}
                  onFocus={() => setTimeout(() => setSearchHasFocus(true))}
                  onBlur={() => setTimeout(() =>setSearchHasFocus(false))}
                  autoComplete='off'
                  className='searchInput'
                  value={searchString}
                />
                {/* Note that the built-in search cancel button does not work on iOS */}
              </td>
              <td className='cancelSearchCell'>
                <div
                  hidden={!searchHasFocus && searchString === ''}
                  key='cancelSearch'
                  onClick={cancelChurchSearch}
                >
                  <CloseCircleIcon className='cancelSearchIcon'/>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      );
  }

  const spaceRegEx = "[a-z0-9]*[^a-z0-9']+"
  // remove apostrophes before breaking up search terms with regular expression
  const searchTerms = searchString.replace(/['’]/g, '').replaceAll( /\s+/g, spaceRegEx);
  const regEx = new RegExp(searchTerms, 'gi')
  const churchesFiltered = churches.filter(
      church => server_api.stringFromChurch(church).replace(/['’]/g, '').search(regEx) !== -1
  );

  const sortedChurches =
    [
      undefined,
      ...churchesFiltered.sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
    ];

  const churchesRendered = sortedChurches
    .map(churchBeingRendered => {
      const isSelected = churchBeingRendered?.id === church?.id;
      const churchName = churchBeingRendered === undefined ? 'No Church' : server_api.stringFromChurch(churchBeingRendered);
      return (
        <tr className='church' key={churchName}>
          <td className='buttonCell'>
            <label className='custom-radio-btn'>
              <input
                checked={isSelected}
                id='church-input'
                className='churchRadioButton'
                type="radio"
                value={churchName}
                name="church"
                onChange={()=>{onSetChurch(churchBeingRendered)}}
              />
              <span className='checkmark'/>
            </label>
          </td>
          <td className='churchCell'>
            <div
              id='church-name'
              className={'churchName' + (isSelected ? ' selectedChurch' : '')}
              onClick={()=>{onSetChurch(churchBeingRendered)}}
            >
              {churchName}
            </div>
          </td>
        </tr>
      );
    });

  return (
    <OuterPageContent>
      {renderChurchSearchControl()}
      <InnerPageContent>
        <table className="churches">
          <tbody>
            {churchesRendered}
          </tbody>
        </table>
      </InnerPageContent>
    </OuterPageContent>
  )
};
