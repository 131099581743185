import {addListener, cacheGet, CacheKey, cacheSet, removeListener, CachedType} from "./client_cache";
import {useCallback, useEffect, useState} from "react";

export function useCache<T extends CacheKey>(key: T, defaultOverride?: CachedType<T>): [
  value: CachedType<T>,
  setValue: (value: CachedType<T>) => void,
] {
  const [value, setValue] = useState<CachedType<T>>(() => cacheGet(key, defaultOverride));

  useEffect(() => {
    const listener = () => setValue(cacheGet(key, defaultOverride));
    addListener(key, listener);
    return () => removeListener(key, listener);
  }, [defaultOverride, key]);

  const updateCache = useCallback((newValue: CachedType<T>) => {
    cacheSet(key, newValue);
  }, [key])

  return [value, updateCache];
}
